<template>
  <div class="container about">
    <div class="text-center p-3">
      <h2 class="header">Who am I?</h2>
      <p class="p-2">
        I am a passionate developer with a constant strive to dig myself deeper
        into the jungle of tech. I mostly do web development professionally but
        I don't like to limit myself to any specific technology or side of the
        stack. On my spare time I like to hit the gym, go on afterworks and
        spend time with my friends. If I get time to spare I tend to learn
        new languages and frameworks, my newest passion is
        <a href="https://www.rust-lang.org/">rust</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>