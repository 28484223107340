<template>
  <div class="gradient app-footer d-flex align-items-center">
    <div class="container text-center">
      <small class="text-white">All rights reserved @ 2021</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss" scoped>
.app-footer {
  height: 100px;
}
</style>
